import React from "react";
import { graphql, Link } from "gatsby";
import Slider from "react-slick";
import {
  FaHammer,
  FaCaretRight,
  FaChevronRight,
  FaChevronLeft,
  FaClosedCaptioning,
  FaArrowDown,
  FaPhone
} from "react-icons/fa";
import Input from "../components/Input";
import { node } from "prop-types";
import Project from "../components/Project";
import ProjectConcise from "../components/ProjectConcise";
import Logo from "../components/Logo";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

import { graphQLResultHasError } from "apollo-utilities";
import { formUri } from "../helpers.js";
import Loader from "react-loader-spinner";
import Footer from "../layout/Footer";
import Carousel from "react-images";
import CookieConsent from "react-cookie-consent";

import makeCarousel from "react-reveal/makeCarousel";
// we'll need the Slide component for sliding animations
// but you can use any other effect
import Slide from "react-reveal/Slide";
import { Helmet } from "react-helmet";

class Index extends React.Component {
  //const Index = ({ data: { prismicHomepage } }) => {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      formValid: false,
      activeProject: 0,
      sendingMail: false,
      mailSentSuccess: false,
      invertedLogo: false,
      showBackgroundLogo: false
    };
  }

  componentDidMount() {
    /*if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      SmoothScroll('a[href*="#"]');
    }*/
  }
  validateFiles = e => {
    const total = Array.from(e.target.files).reduce((total, file) => {
      return total + file.size;
    }, 0);
    if (total > 35000000) {
      alert("Totaal van bestanden is te groot");
      return;
    }
    this.setState({ files: Array.from(e.target.files) });
    //;
  };

  validateForm = e => {};

  nextProject = () => {
    const length = this.props.data.allPrismicProject.edges.length;
    const { activeProject } = this.state;
    let nextProject = 0;

    if (activeProject === length - 1) {
      nextProject = 0;
    } else {
      nextProject = activeProject + 1;
    }

    this.setState({ activeProject: nextProject });
  };

  previousProject = () => {
    const length = this.props.data.allPrismicProject.edges.length;
    const { activeProject } = this.state;
    let previousProject = 0;

    if (activeProject === 0) {
      previousProject = length - 1;
    } else {
      previousProject = activeProject - 1;
    }

    this.setState({ activeProject: previousProject });
  };

  scrollToRef = ref => {
    ref.scrollIntoView({ behavior: "smooth" });
    //window.scrollTo(0, ref.offsetTop);
  };

  // todo: optimise
  scrollToIntro = () => {
    this.infoRef.scrollIntoView({ behavior: "smooth" });
  };

  handleScroll = () => {
    /*if (window.pageYOffset + 200 >= this.contactRef.offsetTop) {
      this.setState({ invertedLogo: true });
    } else {
      this.setState({ invertedLogo: false });
    }*/

    if (typeof window !== "undefined" && window.pageYOffset > 150) {
      this.setState({ showBackgroundLogo: true });
    } else {
      this.setState({ showBackgroundLogo: false });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ sendingMail: true, mailSentSuccess: false });
    const data = new FormData(event.target);

    fetch(formUri, {
      method: "POST",
      body: data
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        // todo dit terug aanpassen
        if (json.success) {
          this.setState({ sendingMail: false, mailSentSuccess: true });
        } else {
          //throw "Oei er ging iets mis. Probeer opnieuw of contacteer ons op info@renovant.be";
        }

        //this.setState({ sendingMail: false, mailSentSuccess: true });
      })
      .catch(() => {
        this.setState({ sendingMail: false, mailSentSuccess: true });

        /*alert(
          "Oei er ging iets mis. Probeer opnieuw of contacteer ons op info@renovant.be"
        );*/
      });
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  render() {
    const {
      data: { prismicHomepage, allPrismicProject, allPrismicQuote }
    } = this.props;

    const sliderSettings = {
      quotes: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    };

    const CarouselUI = ({ children }) => <div>{children}</div>;
    const CarouselReveal = makeCarousel(CarouselUI);

    return (
      <React.Fragment>
        <main className="bg-primary relative overflow-hidden">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Renovant - {prismicHomepage.data.subtitle.text}</title>
            <meta
              name="description"
              content={prismicHomepage.data.intro.html}
            />
            <meta property="og:url" content={"https://www.renovant.be"} />
            <meta
              property="og:title"
              content={prismicHomepage.data.title.text}
            />
            <meta
              property="og:description"
              content={prismicHomepage.data.intro.html}
            />
            <meta
              property="og:image"
              content={prismicHomepage.data.about_image.url}
            />
            <meta
              name="google-site-verification"
              content="WOXI1O41Nqmx9lEzSt0fhO9n7V86BUHtN8Lzvk9sXCY"
            />
            <meta
              name="keywords"
              content="renovatie, karweiwerken, elektriciteit, loodgieterij, badkamerrenovatie, ruwbouw, poutrelle plaatsen riolering, epdm, tim engels, karweiwerken Tim Engels, tegelwerken Kapellen, stabroek, ekeren, kalmthout, essen, merksem, noord antwerpen"
            />
            <link rel="canonical" href="https://www.renovant.be" />
          </Helmet>
          <Logo
            inverted={this.state.invertedLogo}
            showBackground={this.state.showBackgroundLogo}
            title={prismicHomepage.data.title.text}
          />

          <header className="bg-black header-home relative flex items-center">
            <div className="container mx-auto p-2 sm:p-4 flex flex-col items-center justify-around text-primary z-10 relative">
              <h1 className="text-center py-10 sm:py-20">
                {prismicHomepage.data.title.text}
              </h1>

              <img
                src={"renovant-arrow.svg"}
                alt="Renovant logo"
                className="w-8 sm:w-10 cursor-pointer bounce"
                onClick={() => this.scrollToRef(this.introRef)}
              />
            </div>
            {false && (
              <div
                style={{
                  backgroundImage:
                    "url(" + prismicHomepage.data.header_image.url + ")"
                }}
                className="absolute w-full h-full opacity-50 top-0 left-0 bg-center bg-cover"
              />
            )}
            <div className="absolute w-full h-full opacity-50 top-0 left-0 ">
              <CarouselReveal
                defaultWait={3000}
                swipe={false}
                maxTurns={500} /*wait for 1000 milliseconds*/
              >
                {prismicHomepage.data.header_slides.map(image => (
                  <Slide right>
                    <div
                      style={{
                        backgroundImage: "url(" + image.slide_image.url + ")"
                      }}
                      className="w-full h-full bg-center bg-cover"
                    />
                  </Slide>
                ))}
              </CarouselReveal>
            </div>
          </header>
          <section className="bg-grey-lighter relative">
            <div ref={ref => (this.introRef = ref)} className={`intro-ref`} />
            <div className="container mx-auto px-4 flex flex-col items-center justify-center text-center justify-around">
              <Zoom>
                <h2 className="my-10 text-4xl">
                  {prismicHomepage.data.subtitle.text}
                </h2>
                <div className="hidden md:flex flex-row flex-wrap justify-center items-center my-5">
                  <img
                    src={"icon-brick.svg"}
                    alt="Icon brick"
                    className="w-32 p-2"
                  />
                  <img
                    src={"icon-bolt.svg"}
                    alt="Icon brick"
                    className="w-32 p-2"
                  />
                  <img
                    src={"icon-drop.svg"}
                    alt="Icon brick"
                    className="w-32 p-2"
                  />
                  <img
                    src={"icon-spade.svg"}
                    alt="Icon brick"
                    className="w-32 p-2"
                  />
                  <img
                    src={"icon-wall.svg"}
                    alt="Icon brick"
                    className="w-32 p-2"
                  />
                  <img
                    src={"icon-hammer.svg"}
                    alt="Icon brick"
                    className="w-32 p-2"
                  />
                </div>

                <div
                  className="text-2xl my-10"
                  dangerouslySetInnerHTML={{
                    __html: prismicHomepage.data.intro.html
                  }}
                />
                <div
                  className="text-xl my-10"
                  dangerouslySetInnerHTML={{
                    __html: prismicHomepage.data.intro_2.html
                  }}
                />
                <button
                  className="tracking-wide rounded-full text-sm my-10 py-4 px-5 bg-black hover:bg-primary text-primary hover:text-black uppercase inline-flex hover:border-black my-2"
                  type="submit"
                  onClick={() => this.scrollToRef(this.contactRef)}
                >
                  contacteer renovant{" "}
                  <span className="pl-3">
                    <FaChevronRight />
                  </span>
                </button>
              </Zoom>
            </div>
          </section>
          <section className="bg-primary">
            <div className="flex items-center justify-around flex-col md:flex-row">
              <img
                className="md:w-1/2"
                src={prismicHomepage.data.about_image.url}
                alt={prismicHomepage.data.about_image.alt}
              />
              <div className="md:w-1/2 p-16">
                <div
                  className="mb-4 text-4xl"
                  dangerouslySetInnerHTML={{
                    __html: prismicHomepage.data.about_title.html
                  }}
                />
                <div
                  className="mb-10 text-lg"
                  dangerouslySetInnerHTML={{
                    __html: prismicHomepage.data.about_body.html
                  }}
                />
                <button
                  className="tracking-wide rounded-full text-sm py-4 px-5 bg-black hover:bg-primary text-primary hover:text-black uppercase inline-flex border-primary border hover:border-black mt-10"
                  type="submit"
                  onClick={() => this.scrollToRef(this.contactRef)}
                >
                  contacteer renovant{" "}
                  <span className="pl-3">
                    <FaChevronRight />
                  </span>
                </button>
              </div>
            </div>
          </section>
          <section className="min-h-screen-half flex items-center">
            <div className="flex flex-wrap w-full">
              {allPrismicProject.edges.map((project, index) => {
                return (
                  <ProjectConcise
                    project={project}
                    index={index}
                    activeProject={this.state.activeProject}
                    nextProject={this.nextProject}
                    previousProject={this.previousProject}
                    scrollToContact={() => this.scrollToRef(this.contactRef)}
                  />
                );
              })}
            </div>
          </section>
          <section
            className="bg-grey-lighter py-10"
            ref={ref => (this.contactRef = ref)}
          >
            <div className="container mx-auto px-4 flex items-center justify-center quotes-section">
              <Slider
                {...sliderSettings.quotes}
                className="w-full md:w-1/2 text-center"
              >
                {allPrismicQuote.edges.map((quote, index) => {
                  return (
                    <div className="quote">
                      <blockquote
                        className="text-3xl py-10"
                        dangerouslySetInnerHTML={{
                          __html: quote.node.data.quote.html
                        }}
                      />
                      <div
                        className="text-xs uppercase pt-5"
                        dangerouslySetInnerHTML={{
                          __html: quote.node.data.name.html
                        }}
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </section>
          <section className="bg-primary relative">
            <div
              ref={ref => (this.contactRef = ref)}
              className={`contact-ref`}
            />

            <div className="container mx-auto px-4">
              <h2 className="text-center font-normal text-3xl mt-32">
                Heb je vragen of wil je een vrijblijvende offerte?
              </h2>
              <h2 className="text-center font-normal text-3xl  mb-20">
                Aarzel niet om ons te contacteren.
              </h2>
              <form
                className=""
                action="https://formspree.io/f/xwkwakvp" method="POST"
              >
                <div className="flex flex-wrap">
                  <div className="w-full sm:w-1/2 mb-8 md:pr-4">
                    <Input name={"achternaam"} label={"Achternaam"} />
                  </div>
                  <div className="w-full sm:w-1/2 mb-8 md:pl-4">
                    <Input name={"voornaam"} label={"Voornaam"} />
                  </div>

                  <div className="w-full sm:w-1/2 mb-8 md:pr-4">
                    <Input
                      name={"telefoonnummer"}
                      label={"Telefoonnummer"}
                      required={true}
                      placeholder={"+32 000 00 00 00"}
                    />
                  </div>
                  <div className="w-full sm:w-1/2 mb-8 md:pl-4">
                    <Input
                      name={"emailadres"}
                      label={"Emailadres"}
                      type="email"
                    />
                  </div>
                  <div className="w-full mb-8">
                    <Input name={"adres"} label={"Adres"} />
                  </div>
                  { false &&

                  <div className="w-full mb-8 ">
                    <label className="uppercase block tracking-wide text-xs text-black mb-1">
                      Upload bestanden of foto's (max. 35 MB)
                    </label>
                    <div
                      onClick={() => this.inputFileElement.click()}
                      className="file-picker btn cursor-pointer rounded-full text-sm py-4 px-5 bg-primary text-black hover:text-black  border border-black outline-none w-full relative"
                    >
                      <div className="inline-block">+</div>
                      <div className="files inline-block pl-4">
                        {this.state.files &&
                          this.state.files.map(file => {
                            return <span className="file">{file.name}</span>;
                          })}
                      </div>
                    </div>
                    <input
                      className="hidden"
                      ref={input => (this.inputFileElement = input)}
                      type="file"
                      name={"files[]"}
                      multiple
                      onChange={this.validateFiles}
                    />
                  </div>
  }
                  <div className="w-full mb-8">
                    <label
                      className="uppercase block tracking-wide text-xs text-black mb-1"
                      for="bericht"
                    >
                      Uw vraag
                    </label>
                    <textarea
                      name="bericht"
                      placeholder="Uw vraag"
                      className="btn rounded-lg py-2 px-4 bg-primary text-black hover:text-black leading-tight border border-black outline-none w-full h-12 h-48"
                    />
                  </div>
                  <input
                    className="allo-koko"
                    name={"allo-koko"}
                    style={{ display: "none" }}
                  />

                  {this.state.sendingMail || this.state.mailSentSuccess ? (
                    <div className="my-3 text-center flex w-full justify-center">
                      {!this.state.mailSentSuccess ? (
                        <Loader
                          type="Puff"
                          color="#000"
                          height="100"
                          width="100"
                        />
                      ) : (
                        <div className="text-2xl">
                          Uw mail werd verstuurd! We nemen zo snel mogelijk
                          contact met u op.
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="w-full mt-24 text-center">
                      <button
                        className="tracking-wide rounded-full text-sm py-4 px-5 bg-black hover:bg-primary text-primary hover:text-black uppercase inline-flex hover:border-black border-primary border"
                        type="submit"
                      >
                        stuur je vraag naar renovant{" "}
                        <span className="pl-3">
                          <FaChevronRight />
                        </span>
                      </button>
                    </div>
                  )}

                  <div class="w-full my-32 text-4xl text-center">
                    <div>035350285</div>
                    <div className="mt-10 text-lg">
                      PLANTINLAAN 13, 2940 STABROEK
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
          <Footer />
          <CookieConsent
            location="bottom"
            buttonText="ok!"
            style={{ color: "#fff" }}
            buttonStyle={{
              backgroundColor: "#ECDF43",
              color: "black",
              borderRadius: "15px",
              padding: "10px"
            }}
            expires={150}
          >
            Deze website gebruikt cookies.
          </CookieConsent>
          <div class="block sm:hidden fixed z-50 mobile-contact">
            <Fade right>
              <a
                href="tel:+32486030776"
                className="tracking-wide rounded-full text-sm py-4 px-5 bg-primary hover:bg-black text-black hover:text-primary uppercase inline-flex  my-2"
              >
                <div className="block">
                  <FaPhone /> Bel ons
                </div>
              </a>
            </Fade>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query IndexQuery {
    prismicHomepage {
      data {
        title {
          text
        }
        subtitle {
          text
        }
        intro {
          html
        }
        intro_2 {
          html
        }
        header_image {
          url
          copyright
          alt
        }
        about_title {
          html
        }
        about_body {
          html
        }
        about_image {
          url
          copyright
          alt
        }
        header_slides {
          slide_image {
            url
            copyright
            alt
          }
        }
        cta_block {
          cta_image {
            url
            copyright
            alt
          }
          cta_title {
            html
          }
          cta_body {
            html
          }
        }
      }
    }
    allPrismicProject {
      edges {
        node {
          data {
            title {
              html
            }
            location {
              html
            }
            description {
              html
            }
            images {
              project_image {
                url
              }
            }
          }
        }
      }
    }
    allPrismicQuote {
      edges {
        node {
          data {
            quote {
              html
            }
            name {
              html
            }
          }
        }
      }
    }
  }
`;
