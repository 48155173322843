import React from "react";

const Input = ({ name, label, type, required, placeholder = false}) => (
  <React.Fragment>
    <label
      className="uppercase block tracking-wide text-xs text-black mb-1"
      for={name}
    >
      {label}
      {required && <span className="text-red">*</span>}
    </label>
    <input
      className="btn rounded-full py-2 px-4 bg-primary text-black hover:text-black leading-tight border border-black outline-none w-full"
      id={name}
      name={name}
      type={type}
      placeholder={placeholder ? placeholder : label}
      required={required}
    />
  </React.Fragment>
);

Input.defaultProps = {
  type: "text",
  required: false
};

export default Input;
