import React, { Component } from "react";
import Slider from "react-slick";
import {
  FaBeer,
  FaCaretRight,
  FaChevronRight,
  FaChevronLeft,
  FaClosedCaptioning
} from "react-icons/fa";
import Carousel, { Modal, ModalGateway } from 'react-images';

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }


  render() {
    const {
      project,
      index,
      activeProject,
      nextProject,
      previousProject,
      scrollToContact
    }
        = this.props;


    const sliderSettings = {
      image: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    };

    const images = project.node.data.images.map(image => {
      return {"source": image.project_image.url}
    });


    const modalIsOpen = true;

    return (
        <React.Fragment>
          {index === activeProject && (
              <div className="md:flex">
                <div className="lg:w-1/4 md:flex md:flex-col md:justify-between bg-grey">
                  <div className="p-10">
                    <div
                        className="text-primary text-xl"
                        dangerouslySetInnerHTML={{
                          __html: project.node.data.location.html
                        }}
                    />
                    <div
                        className="text-primary text-5xl mb-10"
                        dangerouslySetInnerHTML={{
                          __html: project.node.data.title.html
                        }}
                    />
                    <div
                        className="text-primary text-xl mb-10"
                        dangerouslySetInnerHTML={{
                          __html: project.node.data.description.html
                        }}
                    />
                    <button
                        className="tracking-wide rounded-full text-sm py-4 px-5 bg-primary hover:bg-black text-black hover:text-primary uppercase inline-flex hover:border-primary border-black border"
                        type="submit"
                        onClick={scrollToContact}
                    >
                      contacteer renovant{" "}
                      <span className="pl-3">
                  <FaChevronRight/>
                </span>
                    </button>
                  </div>

                  <div
                      className="slider-navigation flex bg-grey text-grey-darker items-center justify-center border-t border-grey-dark ">
                    <div
                        onClick={() => {
                          previousProject();
                        }}
                        className="w-1/2 flex min-h-full justify-center hover:bg-grey-darker hover:text-primary cursor-pointer"
                    >
                      <div className="flex flex-col self-center items-center">
                        <FaChevronLeft size="75px"/>
                        <div className="py-3 text-xl">vorig project</div>
                      </div>
                    </div>
                    <div
                        onClick={() => nextProject()}
                        className="w-1/2 flex min-h-full justify-center hover:bg-grey-darker hover:text-primary cursor-pointer"
                    >
                      <div className="flex flex-col self-center items-center">
                        <FaChevronRight size="75px"/>
                        <div className="py-3 text-xl">volgend project</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={(e) => this.setState({ modalOpen: true })}>
                  knopke ->
                </div>
                <ModalGateway>
                  {this.state.modalOpen ? (
                      <Modal onClose={() => this.setState({modalOpen: false})}>
                        <Carousel views={images}/>
                      </Modal>
                  ) : null}
                </ModalGateway>


                {false && <Slider className="lg:w-3/4 slider-image" {...sliderSettings.image}>
                  {project.node.data.images.map(image => {
                    return <div className="project-image">
                      <div className="h-full w-full" style={{background: `url(${image.project_image.url})`}}></div>
                      <img src={image.project_image.url}/>
                    </div>
                        ;
                  })}
                </Slider>}
              </div>
          )}
        </React.Fragment>);
  }
}
export default Project;
