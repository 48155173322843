import React, { Component } from "react";
import Slider from "react-slick";
import {
  FaBeer,
  FaCaretRight,
  FaChevronRight,
  FaChevronLeft,
  FaClosedCaptioning
} from "react-icons/fa";
import Carousel, { Modal, ModalGateway } from 'react-images';
import striptags from 'striptags';

const FooterCount = ({currentIndex, views}) => {
  return(
      <div>
        {++currentIndex} van {views.length}
      </div>
  )
};

class ProjectConcise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }


  render() {
    const {
      project,
      index,
      activeProject,
      nextProject,
      previousProject,
      scrollToContact
    }
        = this.props;


    const images = project.node.data.images.reduce((images, image) => {
        if(image.project_image.url) {
          images.push({"source": image.project_image.url, "caption" : `${striptags(project.node.data.title.html)} (${striptags(project.node.data.location.html)})`})
        }
        return images;
    }, []);


    const image = images.length > 0 && images[0].hasOwnProperty("source") ? images[0].source : "";

    const customStyles = {
      view: (base, state) => ({
        // none of react-images styles are passed to <View />
        ...base,
        background:'black'
      }),
      footer: (base, state) => {
        const opacity = state.interactionIsIdle ? 0 : 1;
        const transition = 'opacity 300ms';

        return { ...base, opacity, transition };
      }
    }


    return (
        <React.Fragment>
          <div onClick={(e) => this.setState({modalOpen: true})} className="cursor-pointer w-full md:w-1/3 p-5 ">
            <div className="h-full relative z-10 hover:z-0 flex flex-col">
              <div className="project-title-wrapper bg-black text-primary p-3 relative z-10 hover:z-0 hover:bg-black">
                <div
                    className="text-xl"
                    dangerouslySetInnerHTML={{
                      __html: project.node.data.location.html
                    }}
                />
                <div
                    className="text-2xl"
                    dangerouslySetInnerHTML={{
                      __html: project.node.data.title.html
                    }}
                />
              </div>
              <div className="project-title-description p-3 relative z-10 hover:z-0 h-full hover:bg-black hover:text-primary">
                <div
                    className="text-xl pb-10 "
                    dangerouslySetInnerHTML={{
                      __html: project.node.data.description.html
                    }}
                />
              </div>

              <div
                  style={{backgroundImage:`url(${image})`}}
                  className="absolute w-full h-full opacity-50 top-0 left-0 bg-center bg-cover"
              >

              </div>
            </div>



          </div>
          <ModalGateway>
            {this.state.modalOpen ? (
                <Modal onClose={() => this.setState({modalOpen: false})}>
                  <Carousel views={images} components={{ FooterCount }} styles={customStyles} />
                </Modal>
            ) : null}
          </ModalGateway>
        </React.Fragment>
      );
  }
}
export default ProjectConcise;
